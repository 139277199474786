import React from "react";
import { Container, Typography } from "@mui/material";
import roup from "../assets/roup.jpg";
import road1 from "../assets/road1.jpg";
import flatimg from "../assets/flatimg.jpg";
const AboutUs: React.FC = () => {
  return (
    <div
      style={{ textAlign: "center", marginBottom: "20px", marginTop: "40px" }}
    >
      <div
        style={{
          backgroundImage: `url(${flatimg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          height: "60vh",
          color: "white",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          style={{ fontFamily: "Lato", fontWeight: 700 }}
        >
          About Us
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          textAlign: "center",
          padding: "40px",
        }}
      >
        <div style={{ flex: 1 }}>
          <Typography
            variant="h4"
            style={{
              textAlign: "left",
              fontStyle: "nunito, sans-serif",
              fontWeight: 900,
              fontFamily: "Lato",
            }}
          >
            Road Contractors in Coimbatore
          </Typography>
          <p style={{ textAlign: "left", fontFamily: "Lato" }}>
            Are you seeking the top Road Contractors in Coimbatore? Look no
            further than{" "}
            <span style={{ fontWeight: "bold", fontFamily: "Lato" }}>
              RAS Roadworks!
            </span>
            <br />
            <br />
            With a dedicated team of skilled professionals, RAS Roadworks offers
            a wide range of services and equipment tailored to meet the needs of
            clients in Coimbatore and its surrounding areas.
            <br />
            <br />
            Over the years, RAS Roadworks has successfully completed over a
            thousand projects including Road Construction, Maintenance, Pavement
            Markings, Signage Installation, and Infrastructure Development. Our
            commitment to quality, timely delivery, and efficient utilization of
            resources has earned us recognition and trust among our clients.
            <br />
            <br />
            Whether you require the construction of new roads, repair of
            existing infrastructure, or implementation of innovative solutions,
            RAS Roadworks is your reliable partner for all your road contracting
            needs. Contact us today to learn more about our services and how we
            can assist you in achieving your project goals.
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <img src={road1} style={{ width: "60%" }} />
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
