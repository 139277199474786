import React, { useState, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import road1 from "../assets/road.jpg";
import Container from "../assets/Container.png";
const Home: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [Container];
  const handleservice = () => {
    window.open("/services", "_blank");
  };

  return (
    <div
      style={{ textAlign: "center", marginBottom: "20px", marginTop: "40px" }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "inline-block",
              width: "100%",
              height: "600px",
              color: "white",
              textAlign: "center",
              marginBottom: "20px",
              cursor: "pointer",
            }}
            className={
              index === currentSlide ? "banner-image active" : "banner-image"
            }
          >
            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                marginTop: "25vh",
                fontFamily: "Lato",
                fontWeight: 700,
              }}
            >
              {" "}
            </Typography>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          textAlign: "center",
          padding: "40px",
        }}
      >
        <div style={{ flex: 1 }}>
          <Typography
            variant="h4"
            style={{
              textAlign: "left",
              fontStyle: "nunito, sans-serif",
              fontWeight: 900,
              fontFamily: "Lato",
            }}
          >
            RasRoad Works
          </Typography>
          <p style={{ textAlign: "left", fontFamily: "Lato" }}>
            RasRoad Works is a leading road construction company dedicated to
            delivering high-quality infrastructure solutions in the Ras region
            and beyond. With a team of experienced engineers, technicians, and
            professionals, we specialize in executing road construction projects
            of varying scales and complexities. Our commitment to excellence,
            innovation, and sustainability drives us to deliver projects that
            not only meet but exceed our clients' expectations. At RasRoad
            Works, we prioritize customer satisfaction and strive to provide the
            best service possible. Our in-house experts meticulously plan and
            execute each project, ensuring adherence to industry standards and
            safety regulations.
          </p>
          <Button
            onClick={handleservice}
            style={{
              backgroundColor: "#4CAF50",
              borderRadius: "20px",
              color: "white",
              padding: "10px 20px",
              fontWeight: "bold",
              textTransform: "none",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "1px",
              borderBottomLeftRadius: "1px",
              borderBottomRightRadius: "20px",
            }}
          >
            Learn More
          </Button>
        </div>
        <div style={{ flex: 1 }}>
          <img src={road1} style={{ width: "60%" }} />
        </div>
      </div>
    </div>
  );
};

export default Home;
