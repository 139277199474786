import React, { ReactNode } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import WhatsAppWidget from "./WhatsAppWidget";
interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div>
      <Navbar />
      <div
        style={{
          paddingBottom: "60px",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        {children}
      </div>
      <WhatsAppWidget />

      <Footer />
    </div>
  );
};
export default Layout;
