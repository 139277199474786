import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import bridgeroad from "../assets/bridgeroad.jpg";
import GalleryModal from "../components/GalleryModal";
import constructionimg from "../assets/constructionimg.jpeg";
import road5 from "../assets/road5.jpeg";
import road8 from "../assets/road8.jpg";
import roadimg from "../assets/roadimg.jpeg";
import roadimg2 from "../assets/roadimg2.jpeg";
import roadimg4 from "../assets/roadimg4.jpeg";
import mudimg from "../assets/mudimg.jpeg";
import no1 from "../assets/no1.jpg";
import mudimg2 from "../assets/mudimg2.jpeg";
import gallery from "../assets/gallery.png";
const Gallery: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [zoomFactor, setZoomFactor] = useState(1);
  const [zoomIn, setZoomIn] = useState(true);
  const handleOpenImage = (image: string) => {
    setSelectedImage(image);
    setOpen(true);
    setZoomFactor(1);
  };
  const handleCloseImage = () => {
    setOpen(false);
  };
  const handleZoom = () => {
    if (zoomIn) {
      setZoomFactor(zoomFactor + 0.8);
    } else {
      setZoomFactor(zoomFactor - 0.8);
    }
    setZoomIn(!zoomIn);
  };
  const [openModal, setOpenModal] = useState(false);
  const firstRowImages = [mudimg2, roadimg, roadimg2];
  const secondRowImages = [road5, mudimg2, roadimg4];
  const handleOpenModal = (image: string) => {
    setSelectedImage(image);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const allImages = [...firstRowImages, ...secondRowImages];
  return (
    <div
      style={{
        textAlign: "center",
        marginBottom: "20px",
        marginTop: "60px",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${road8})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "inline-block",
          width: "100%",
          height: "400px",
          color: "#FFFFFF",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h3"
          style={{ textAlign: "center", marginTop: "25vh", fontFamily: "Lato" }}
        >
          Gallery
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        {firstRowImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            style={{
              width: "300px",
              height: "250px",
              marginRight: "40px",
              marginBottom: "20px",
              borderRadius: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal(image)}
          />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        {secondRowImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${firstRowImages.length + index + 1}`}
            style={{
              width: "300px",
              height: "250px",
              marginRight: "40px",
              marginBottom: "20px",
              borderRadius: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal(image)}
          />
        ))}
      </div>
      {/* 
      <Modal open={open} onClose={handleCloseImage} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-30vh" }}>
        <>
          <div style={{ position: "relative", textAlign: "center", maxWidth: "30vw", maxHeight: "30vh", marginTop: "10vh" }}>
            <img src={selectedImage} alt="Selected Image" style={{ maxWidth: "100%", maxHeight: "100%", transform: `scale(${zoomFactor})`, transition: "transform 0.3s" }} />
            </div>
          <div style={{ position: "absolute", top: 10, right: 10, zIndex: 999 }}>
            <IconButton onClick={handleZoom} style={{ color: "#FFFFFF", marginTop: "270px", fontWeight: 700 }}>{zoomIn ? <ZoomInIcon /> : <ZoomOutIcon />}</IconButton>
            <IconButton onClick={handleCloseImage} style={{ color: "#FFFFFF", marginTop: "270px" }}><CloseIcon /></IconButton>
          </div>
        </>
      </Modal> */}
      {/* {allImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Image ${index + 1}`}
          style={{ width: "300px", height: "250px", marginRight: "40px", marginBottom: "20px", borderRadius: "20px", cursor: "pointer" }}
          onClick={() => handleOpenModal(image)}
        />
      ))} */}
      {openModal && (
        <GalleryModal
          images={allImages}
          selectedImage={selectedImage}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};
export default Gallery;
