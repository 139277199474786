import React from "react";
import { Fab } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsAppWidget: React.FC = () => {
  const phoneNumber = "+919688090492";

  const handleOpenChat = () => {
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <Fab
      color="primary"
      aria-label="whatsapp"
      onClick={handleOpenChat}
      style={{
        backgroundColor: "#25d366",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 1000,
      }}
    >
      <WhatsAppIcon />
    </Fab>
  );
};

export default WhatsAppWidget;
